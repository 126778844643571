import React, {Component} from 'react';
import Header from "../Partials/Header";
import {Col, Container, Row} from "react-bootstrap";
import Footer from "../Partials/Footer";
import ContactMe from "../Partials/ContactMe";
import {Helmet} from "react-helmet";

class About extends Component {
    render() {
        return <div>
            <Helmet>
                <title>Janaka Dombawela | About Me</title>
                <meta itemProp="name" content="Janaka Dombawela | About Me"/>
                <meta itemProp="description" content="    Hi, My name is Janaka Dombawela. I've been working on web applications over the last six
                            years with few companies as a engineer and a developer."/>
                <link itemProp="image" href="./assets/images/profile-reduced.jpg"/>
            </Helmet>
            <Header/>
            <Container className={'about-me'} as={'section'} fluid={true} data-aos={'zoom-in'}>
                <Row>
                    <Col className={'text-center background-animation-dark'}>
                        <h2>
                            About Me
                        </h2>
                        <Col className={'description'} sm={{span: 8, offset: 2}}>
                            Hi, My name is Janaka Dombawela. I've been working on web applications over the last six
                            years with few companies as a engineer and a developer.
                            <br/>
                            <br/>
                            I completed my schools with Mathematics subjects as major subjects. Then I completed a HND
                            in IT.
                            <br/>
                            <br/>
                            I was fortunate to working on few different companies with different set of processes and
                            tech.
                            After few years Laravel + React + Vue became my main stack.
                            <br/>
                            <br/>
                            I played Dota 2 for a while. I love anime.
                        </Col>
                    </Col>
                </Row>
            </Container>
            <ContactMe/>
            <Footer/>
        </div>
    }
}

export default About;