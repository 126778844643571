import React, {Component} from 'react';
import Header from "../Partials/Header";
import Footer from "../Partials/Footer";
import {Alert, Button, Col, Container, Form, FormControl, FormGroup, FormLabel, Row} from "react-bootstrap";
import axios from 'axios';
import {Helmet} from "react-helmet";

class Contact extends Component {


    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            subject: '',
            message: '',
            success: false,
            error: false
        };
    }


    render() {

        return <div>
            <Helmet>
                <title>Janaka Dombawela | Contact Me</title>
                <meta itemProp="name" content="Janaka Dombawela | Contact Me"/>
                <meta itemProp="description" content="You can contact me by mailing to janakadombawela397@gmail.com"/>
                <link itemProp="image" href="./assets/images/profile-reduced.jpg"/>
            </Helmet>
            <Header/>
            <Container fluid={true} as={'section'} className={'contact-me'} data-aos={'flip-down'}>
                <Row>
                    <Col className="background-animation-light">
                        <h2 className={'text-center'}>Contact Me</h2>
                        <Col className="description text-center">
                            You can contact me by mailing to <a
                            href="mail:janakadombawela397@gmail.com">janakadombawela397@gmail.com</a>
                            <br/>
                            <br/>
                            or filling the following form:
                        </Col>
                    </Col>
                </Row>
            </Container>
            <Container fluid={true} as={'section'} className={'contact-me'} data-aos={'flip-up'}>
                <Row>
                    <Col>
                        <Col className="form" sm={{span: 8, offset: 2}}>
                            {this.state.success &&
                            <Alert variant={'success'} key={1}>Message sent successfully!</Alert>}
                            {this.state.error &&
                            <Alert variant={'danger'} key={2}>Message sent failed!</Alert>}
                            <Form onSubmit={this.submitForm.bind(this)} validated={true}>
                                <FormGroup>
                                    <Row>
                                        <Col sm={6}>
                                            <FormLabel>Name: <span className="required"></span></FormLabel>
                                            <FormControl onChange={this.setName.bind(this)}
                                                         placeholder={'Name'} required={true}/>
                                        </Col>
                                        <Col sm={6}>
                                            <FormLabel>Email: <span className="required"></span></FormLabel>
                                            <FormControl onChange={this.setEmail.bind(this)} type={'email'}
                                                         placeholder={'Email'} required={true}/>
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup>
                                    <Row>
                                        <Col>
                                            <FormLabel>Subject: <span className="required"></span></FormLabel>
                                            <FormControl onChange={this.setSubject.bind(this)} required={true}
                                                         placeholder={'Subject'}/>
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup>
                                    <Row>
                                        <Col>
                                            <FormLabel>Message: <span className="required"></span></FormLabel>
                                            <FormControl as={'textarea'} onChange={this.setMessage.bind(this)}
                                                         required={true}
                                                         placeholder={'Message'}/>
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup className={'text-center'}>
                                    <Button type={'submit'}>Submit</Button>
                                </FormGroup>
                            </Form>
                        </Col>
                    </Col>
                </Row>
            </Container>
            <Footer/>
        </div>
    }

    setName = (event) => {
        this.setState({name: event.target.value});
    };

    setEmail = (event) => {
        this.setState({email: event.target.value});
    };

    setSubject = (event) => {
        this.setState({subject: event.target.value});
    };

    setMessage = (event) => {
        this.setState({message: event.target.value});
    };

    submitForm = (event) => {
        event.preventDefault();
        event.stopPropagation();

        axios.post('https://backend-janaka.herokuapp.com/email/contact', this.state).then(response => {
            if (response.data) {
                this.setState({success: true});
            } else {
                this.setState({error: true});
            }
        }).catch(error => {
            this.setState({error: true});
        });
    };
}

export default Contact;