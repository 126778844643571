import React, {Component} from "react";
import Header from "../Partials/Header";
import {Col, Container, Row} from "react-bootstrap";
import TimelineRow from "../Partials/Timeline/TimelineRow";
import SkillBarItem from "../Partials/SkillBar/SkillBarItem";
import SingleProject from "../Partials/Project/SingleProject";
import Footer from "../Partials/Footer";
import ContactMe from "../Partials/ContactMe";
import axios from "axios";
import {Helmet} from "react-helmet";

class Home extends Component {
    /**
     *
     * @param props
     */
    constructor(props) {
        super(props);
        this.state = {
            companies: [],
            skills: [],
            projects: [],
        }
    }

    componentDidMount() {
        axios.get('https://backend-janaka.herokuapp.com/data/companies').then(response => {
            this.setState({companies: response.data});
        }).catch(error => {
            console.log(error)
        });

        axios.get('https://backend-janaka.herokuapp.com/data/skills').then(response => {
            this.setState({skills: response.data});
        }).catch(error => {
            console.log(error)
        });

        axios.get('https://backend-janaka.herokuapp.com/data/projects').then(response => {
            this.setState({projects: response.data});
        }).catch(error => {
            console.log(error)
        });
    }

    /**
     *
     * @returns {*}
     */
    render() {
        return (
            <div>
                <Helmet>
                    <title>Janaka Dombawela</title>
                    <meta itemProp="name" content="Janaka Dombawela"/>
                    <meta itemProp="description" content="5+ Years Senior Fullstack Developer Focused on Backend, Skilled In PHP,
                                        Laravel, Node.js, React.js and
                                        Vue.js"/>
                    <link itemProp="image" href="./assets/images/profile-reduced.jpg"/>
                </Helmet>
                <Header/>

                <Container className="hero" fluid as="section">
                    <Row>
                        <Col className="background-animation-light">
                            <Row className="align-items-center">
                                <Col className="image" data-aos={'fade-down-right'}>
                                    <img className="img-fluid"
                                         src="./assets/images/profile-reduced.jpg"
                                         alt="header"/>
                                </Col>
                                <Col className="text-center description" data-aos={'fade-up-left'}>
                                    <h1>Janaka Dombawela</h1>
                                    <Col>
                                        5+ Years Senior Fullstack Developer Focused on Backend, Skilled In PHP,
                                        Laravel, Node.js, React.js and
                                        Vue.js</Col>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
                <Container className="description" fluid as="section">
                    <Row>
                        <Col className="text-center background-animation-dark" data-aos="fade-up">
                            <h2>I am:</h2>
                            <Col>
                                Senior back-end developer with over 5 years experience. I have gained exposure to full
                                stack development as well. Currently my main focus is on PHP and MySQL with Laravel. I
                                use ReactJS and VueJs as front stack. I’ve been lucky to lead and mentor other
                                developers at a senior position.
                                <br/>
                                <br/>
                                I worked with couple of NodeJS projects with ExpressJS as framework too. I am well used
                                with researching new tech and implementing them on applications.
                                <br/>
                                <br/>
                                I prefer to be a full stack developer focused on PHP/MySQL or NodeJS or both. Most
                                importantly I want to build something that I can be proud of.
                                <br/>
                                <br/>
                                I make music and watch Anime on my free time.
                                <br/>
                                <br/>
                            </Col>
                        </Col>
                    </Row>
                </Container>
                <Container className="timeline" fluid as="section">
                    <Row>
                        <Col>
                            <h2 className="text-center" data-aos={'fade-down'}>My Story</h2>
                            <div className="timeline-wrapper">
                                {this.getCompanies()}
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container className="skills" fluid as="section">
                    <Row>
                        <Col>
                            <h2 className="text-center" data-aos={'filp-up'}>Skills</h2>
                            <Row>
                                {this.getSkills()}
                            </Row>
                        </Col>
                    </Row>
                </Container>
                <Container className="projects" fluid as="section">
                    <Row>
                        <Col>
                            <h2 className={"text-center"} data-aos={'flip-down'}>
                                Some Projects
                            </h2>
                            <Row>
                                {this.getProjects()}
                            </Row>
                        </Col>
                    </Row>
                </Container>
                <ContactMe/>
                <Footer/>
            </div>
        );
    }

    /**
     *
     * @returns {[]}
     */
    getCompanies = () => {
        let companies = [];
        this.state.companies.forEach(company => {
            companies.push(<TimelineRow key={company.id} id={company.id} side={company.side} name={company.name}
                                        description={company.description}
                                        month={company.month} year={company.year}
                                        responsibilities={company.responsibilities} skills={company.skills}/>);
        });
        return companies;
    };

    /**
     *
     * @returns {[]}
     */
    getSkills = () => {
        let skills = [];
        this.state.skills.forEach(skill => {
            skills.push(<SkillBarItem key={skill.id} label={skill.label} progress={skill.progress}/>);
        });
        return skills;
    };

    /**
     *
     * @returns {[]}
     */
    getProjects = () => {
        let projects = [];
        this.state.projects.forEach(project => {
            projects.push(<SingleProject key={project.id} id={project.id} name={project.name} url={project.url}
                                         description={project.description}
                                         thumbnail={project.thumbnail}/>)
        });
        return projects;
    }
}


export default Home;